import "./styles.css";
import vcImage from "../assets/virtual-care-graphic.png";
import { Link } from "react-router-dom";

const Splash = (props) => {
  return (
    <div class="container">
      <div class="fstRow">
        <div class="headline">
          Coordinate Telehealth For A Better Patient Experience
        </div>
        <div class="description">
          Create appointments or contact patients on one platform.
        </div>
        <div class="btnRow">
          <Link to={"/appointment"}>
            <button class="btnDefault">Create Appointment</button>
          </Link>
          <Link to={"/notify"}>
            <button class="btnOther">Contact Patient</button>
          </Link>
        </div>
      </div>

      <div class="sndRow">
        <img src={vcImage} alt="virtual care graphic" class="img" />
      </div>
    </div>
  );
};

export default Splash;
