import "./styles.css";
import errorIcon from "../assets/error-icon.svg";

const Error = ({ msg }) => {
  if (!msg) return <div className="error"></div>;
  return (
    <div className="error">
      <img src={errorIcon} />
      {msg}
    </div>
  );
};

export default Error;
