import React from "react";
import "../App.css";
import { Formik, Form, useField } from "formik";
import Error from "../Error/index.js";

const FormInput = ({ CSSClass = "inputBox", label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  return (
    <div class="field">
      <label htmlFor={props.name} class="label">
        {label}
      </label>
      <input class={CSSClass} {...field} {...props} />
      {meta.touched ? (
        <Error msg={meta.error} />
      ) : (
        <div className="error"></div>
      )}
    </div>
  );
};
export default FormInput;
