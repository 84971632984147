const axios = require("axios");

const sendEmail = (email, first_name, msg_body, link, org) => {
  console.log("send email called");
  const data = JSON.stringify({
    address: email,
    first_name: first_name,
    msgBody: msg_body,
    link: link,
    org: org,
  });

  const config = {
    method: "post",
    url: "https://us-west2-website-hosting-309708.cloudfunctions.net/sendEmail",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  console.log("send email config", config);
  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      return response.data;
    })
    .catch(function (error) {
      console.log("error in sendEmail function", error);
      return error;
    });
};

const sendSMS = (mobile_number, msg_body) => {
  console.log("mobile_number", mobile_number);
  console.log("msg_body", msg_body);
  var data = JSON.stringify({
    mobile_number: mobile_number,
    msg: msg_body,
  });

  var config = {
    method: "post",
    url: "https://us-west2-website-hosting-309708.cloudfunctions.net/sendInvite",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};

exports.sendSMS = sendSMS;
exports.sendEmail = sendEmail;
