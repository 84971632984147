exports.convertTime = (time) => {
  let timeUnits = time.split(":");
  let hours = parseInt(timeUnits[0]);
  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  const res_time = hours.toString() + ":" + timeUnits[1] + " " + period;
  return res_time;
};

exports.convertDate = (date) => {
  let dateUnits = date.split("-");
  return dateUnits[1] + "/" + dateUnits[2] + "/" + dateUnits[0];
};
