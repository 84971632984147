import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Formik, Form, useField } from "formik";
import LongInput from "../LongInput/index.js";
import FormInput from "../FormInput/index.js";
import { sendSMS, sendEmail } from "../API/index.js";
import * as Yup from "yup";
import { convertDate, convertTime } from "../utils.js";
import Copiable from "../Copiable/index.js";
import { base_url, org } from "../whitelabel.js";

const MessageConfirmation = () => {
  let navigate = useNavigate();
  let { state } = useLocation();

  useEffect(() => {
    if (state === null) {
      navigate("/Appointment");
    }
  });

  if (state === null) return null;

  const roomName = state.roomName.split(" ").join("-");
  const patientFullname = state.patientFirst + " " + state.patientLast;
  // let base_url = " https://rsbcihi.meetmonk.com/#/";
  let full_url = base_url + roomName;

  let patientTemplate = "";
  let clinicianTemplate = "";
  let email_body = "";

  if (state !== null) {
    const body =
      "You have a virtual care appointment on " +
      convertDate(state.appDate) +
      " at " +
      convertTime(state.appTime);

    patientTemplate =
      "Dear " +
      state.patientFirst +
      " " +
      state.patientLast +
      ",\n" +
      body +
      " with Dr. " +
      state.clinicianFirst +
      " " +
      state.clinicianLast +
      ". Please use the following link:" +
      full_url;

    email_body =
      body +
      " with Dr. " +
      state.clinicianFirst +
      " " +
      state.clinicianLast +
      ".";

    if (state.clinicianMobile) {
      clinicianTemplate =
        "Dear Dr. " +
        state.clinicianFirst +
        " " +
        state.clinicianLast +
        ",\n" +
        body +
        " with " +
        state.patientFirst +
        " " +
        state.patientLast +
        ". Please use the following link:" +
        full_url;
    }
  }

  return (
    <Formik
      initialValues={{
        patientMsg: patientTemplate,
        clinicianMsg: clinicianTemplate,
      }}
      validationSchema={Yup.object({
        patientMsg: Yup.string()
          .required("Message is required")
          .max(1600, "Message must be 1600 characters or less."),
        clinicianMsg: Yup.string().max(
          1600,
          "Message must be 1600 characters or less."
        ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        try {
          if (state.patientMobile)
            sendSMS(state.patientMobile, values.patientMsg);
          if (state.email) {
            sendEmail(
              state.email,
              state.patientFirst,
              email_body,
              full_url,
              org
            );
          }
          if (values.clinicianMsg && state.clinicianMobile)
            sendSMS(state.clinicianMobile, values.clinicianMsg);
        } catch (error) {
          alert("That didn't work.", error);
          return;
        }
        navigate("/completion", { state: state });
      }}
    >
      <div className="msgContainer">
        <div class="infoRow">
          <div class="infoSeg">
            <div class="infoLabel">ROOM NAME</div>
            <div className="roomName">{state.roomName}</div>
          </div>
          <div className="infoSeg">
            <div className="infoLabel">PATIENT NAME</div>
            <div className="patientName">{patientFullname}</div>
          </div>
        </div>
        <div class="infoRow">
          <div className="infoSeg">
            <div className="infoLabel">ROOM LINK</div>
            <Copiable link={full_url} />
          </div>
        </div>
        <Form>
          <div className="msgRow">
            <LongInput
              label="*Patient Message"
              name="patientMsg"
              type="textarea"
              className="bigInput"
            />
            <LongInput
              label="*Clinician Message"
              name="clinicianMsg"
              type="textarea"
              className="bigInput"
            />
          </div>
          <div className="sendBtnRow">
            <button className="sendBtn" type="submit">
              Send Appointment
            </button>
          </div>
        </Form>
      </div>
    </Formik>
  );
};

export default MessageConfirmation;
