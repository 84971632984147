import logo from "./logo.svg";
import "./App.css";
import Splash from "./Splash";
import { Outlet } from "react-router-dom";
import afyaLogo from "./assets/afya-logo.png";
import MLKLogo from "./assets/MLK_logo.svg";

function App() {
  return (
    <div class="masterContainer">
      <div class="header">
        <img src={MLKLogo} class="clientLogo"></img>
      </div>
      <Outlet />
      <div class="footer">
        <img src={afyaLogo} class="afyaLogo" />
        <p>Powered By Afya</p>
      </div>
    </div>
  );
}

export default App;
