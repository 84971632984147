import { useParams } from "react-router";
import "./styles.css";
import { useEffect } from "react";
import { base_url, org } from "../whitelabel.js";

function getLocalStream() {
  navigator.mediaDevices
    .getUserMedia({ video: true, audio: true })
    .then((stream) => {
      window.localStream = stream; // A
      window.localAudio.srcObject = stream; // B
      window.localAudio.autoplay = true; // C
    })
    .catch((err) => {
      console.log("u got an error:" + err);
    });
}

const Room = ({}) => {
  useEffect(() => {
    getLocalStream();
  });
  let urlSearchParams = useParams();
  console.log("urlSearchParams", urlSearchParams);
  let url = "https://" + org + ".meetmonk.com/#/" + urlSearchParams.roomId;
  return (
    <iframe
      className="room"
      src={url}
      allow="camera; microphone; display-capture"
      allowfullScreen="true"
    />
  );
};

export default Room;
