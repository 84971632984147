import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Appointment from "./routes/appointment";
import Contact from "./routes/contact";
import Splash from "./Splash";
import { AppointmentForm } from "./ApppointmentForm";
import MessageConfirmation from "./MessageConfirmation";
import Notify from "./Notify";
import Completion from "./Completion";
import Room from "./Room";
import RoomContainer from "./RoomContainer";
import NoMatch from "./NoMatch";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Splash />} />
          <Route path="appointment" element={<AppointmentForm />} />
          <Route path="contact" element={<Contact />} />
          <Route path="message" element={<MessageConfirmation />} />
          <Route path="notify" element={<Notify />} />
          <Route path="completion" element={<Completion />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="/room" element={<RoomContainer />}>
          <Route path=":roomId" element={<Room />} />
          <Route index element={<Splash />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
