import { Outlet } from "react-router-dom";
import "./styles.css";

function RoomContainer() {
  return (
    <div className="roomContainer">
      <Outlet />
    </div>
  );
}

export default RoomContainer;
