import { useState } from "react";
import "../App.css";
import FormInput from "../FormInput/index.js";
import { Formik, Form, useField } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import PhoneInput from "react-phone-number-input/input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import Error from "../Error/index.js";

function validateMobile(input, handler) {
  if (!input) {
    handler("Phone number length is invalid");
    console.log("input is invalid");
    return false;
  }
  if (!isPossiblePhoneNumber(input)) {
    handler("Phone number length is invalid");
    return false;
  } else {
    handler("");
    return true;
  }
}

export const AppointmentForm = () => {
  let navigate = useNavigate();
  const [patientMobile, setPatientMobile] = useState();
  const [patientMobileError, setPatientMobileError] = useState();
  const [clinicianMobile, setClinicianMobile] = useState();
  const [clinicianMobileError, setClinicianMobileError] = useState();

  return (
    <Formik
      initialValues={{
        patientFirst: "",
        patientLast: "",
        email: "",
        clinicianFirst: "",
        clinicianLast: "",
        appDate: "",
        appTime: "",
      }}
      validationSchema={Yup.object({
        patientFirst: Yup.string()
          .max(40, "Must be 40 characters or less")
          .required("Patient first name is required"),
        patientLast: Yup.string()
          .max(40, "Must be 40 characters or less")
          .required("Patient last name is required"),
        clinicianFirst: Yup.string()
          .max(40, "Must be 40 characters or less")
          .required("Clinician first name is required"),
        clinicianLast: Yup.string()
          .max(40, "Must be 40 characters or less")
          .required("Clinician last name is required"),
        email: Yup.string().email("Invalid email address"),
        appDate: Yup.string().required("Appointment Date is required"),
        appTime: Yup.string().required("Appointment Time is required"),
        roomName: Yup.string()
          .min(5, "Room name must be atleast 5 letters")
          .matches(
            "^[ A-Za-z0-9_-]+$",
            'Only letters, numbers "-" and "_" are permitted'
          )
          .required("Room name is required"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const patientMobileValid = validateMobile(
          patientMobile,
          setPatientMobileError
        );
        const clinicianMobileValid = validateMobile(
          clinicianMobile,
          setClinicianMobileError
        );
        if (patientMobileValid === false || clinicianMobileValid === false)
          return;
        const formVals = {
          ...values,
          patientMobile: patientMobile,
          clinicianMobile: clinicianMobile,
        };
        navigate("/message", { state: formVals });
        setSubmitting(false);
      }}
    >
      <Form>
        <div class="formContainer">
          <div>
            <FormInput
              label="*Room Name"
              name="roomName"
              type="text"
              // placeholder="Doe Deer 3:45"
            />
          </div>
          <div class="row">
            <FormInput
              label="*Patient First Name"
              name="patientFirst"
              type="text"
              // placeholder="John"
            />
            <FormInput
              label="*Patient Last Name"
              name="patientLast"
              type="text"
              // placeholder="Smith"
            />
            <FormInput
              label="*Patient email"
              name="email"
              type="email"
              // placeholder="JohnSmith@gmail.com"
            />
          </div>
          <div class="row">
            <div class="field">
              <label htmlFor="patientMobile" class="label">
                *Patient Mobile Number
              </label>
              <PhoneInput
                name="patientMobile"
                country="US"
                class="inputBox"
                value={patientMobile}
                onChange={setPatientMobile}
                // placeholder="(555) 555-5555"
                onBlur={() => {
                  validateMobile(patientMobile, setPatientMobileError);
                }}
              />
              <Error msg={patientMobileError} />
            </div>
            <FormInput
              label="*Appointment Date"
              name="appDate"
              type="date"
              // placeholder="11/16/2021"
            />
            <FormInput
              label="*Appointment Time"
              name="appTime"
              type="time"
              // placeholder="16:00"
            />
          </div>
          <div class="row">
            <FormInput
              label="*Clinician First Name"
              name="clinicianFirst"
              type="text"
              // placeholder="Jane"
            />
            <FormInput
              label="*Clinician Last Name"
              name="clinicianLast"
              type="text"
              // placeholder="Doe"
            />
            <div class="field">
              <label htmlFor="clinicianMobile" class="label">
                *Clinician Mobile Number
              </label>
              <PhoneInput
                name="clinicianMobile"
                country="US"
                class="inputBox"
                value={clinicianMobile}
                onChange={setClinicianMobile}
                // placeholder="(555) 555-5555"
                onBlur={() => {
                  validateMobile(clinicianMobile, setClinicianMobileError);
                }}
              />
              <Error msg={clinicianMobileError} />
            </div>
          </div>
          <div class="appBtnRow">
            <button class="submitBtn" type="submit">
              Next
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
