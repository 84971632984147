import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import speechBubble from "../assets/checked-speech-bubble.svg";
import "./styles.css";

const Completion = () => {
  let { state } = useLocation();
  let navigate = useNavigate();
  useEffect(() => {
    if (state === null) {
      navigate("/404");
    }
  });
  if (state === null) return null;
  let description = "Text will be sent shortly";

  let firstWord = "Message";

  if (state.patientFirst) {
    description =
      state.patientFirst +
      " " +
      state.patientLast +
      " will be be sent both a text and an email.";
    firstWord = "Invitation";
  }
  if (state.clinicianFirst) {
    description =
      "Dr. " +
      state.clinicianFirst +
      " " +
      state.clinicianLast +
      " will be receiving a text shortly and " +
      description;
    firstWord = "Invitation";
  }

  let heading = firstWord + " sent!";

  return (
    <div className="completionContainer">
      <img
        src={speechBubble}
        alt="Checked speech bubble"
        className="bubble"
      ></img>
      <div className="completionHeading">{heading}</div>
      <div className="completionDescription">{description}</div>
    </div>
  );
};

export default Completion;
